@font-face {
  font-family: 'thaleahfat';
  src: url('./assets/fonts/ThaleahFat.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Make the font global */
* {
  font-family: 'thaleahfat', helvetica;
}