html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .App-logo {
    height: 30vmin;
  }

  .App-header {
    font-size: calc(10px + 3vmin);
    padding: 15px;
  }

  .App-link {
    font-size: 1em;
  }
}

/* Responsive for narrower screens: 320–414px wide */
@media (max-width: 414px) {
  .App-logo {
    height: 25vmin;
  }

  .App-header {
    font-size: calc(10px + 4vmin);
    padding: 12px;
  }

  .App-link {
    font-size: 0.9em;
  }
}

/* Specific styles for smaller screens: 320–375px wide */
@media (max-width: 375px) {
  .App-logo {
    height: 17vmin;
  }

  .App-header {
    font-size: calc(10px + 4.5vmin);
    padding: 8px;
  }

  .App-link {
    font-size: 0.8em;
  }
}

/* Specific styles for very small screens: 320px wide */
@media (max-width: 320px) {
  .App-logo {
    height: 18vmin;
  }

  .App-header {
    font-size: calc(10px + 5vmin);
    padding: 5px;
  }

  .App-link {
    font-size: 0.75em;
  }
}


